import React from "react";
import styled from "styled-components";

import { NewIcon } from "components/NewIcon";

const Button = styled.button`
  color: #000;
  backdrop-filter: blur(200px);
  background: #fff8;
  border: 0;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  cursor: pointer;
  opacity: var(--arrow-buttons-opacity);
  visibility: var(--arrow-buttons-visibility);
  transition: opacity 0.2s ease, visibility 0.2s ease;
`;

export const ArrowButton = ({ direction, onClick }: { direction: "previous" | "next"; onClick: () => void }) => (
  <Button onClick={onClick}>
    <NewIcon icon={`chevron-${direction === "next" ? "right" : "left"}`} width="30" height="30" />
  </Button>
);
