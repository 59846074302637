import React, { cloneElement, ComponentClass, createElement, FC, FunctionComponent, memo, ReactElement } from "react";
import Converter from "html-to-react";

import { deeplinkTranslator } from "modules/App/deeplinkTranslator";
import { Link } from "../link";

interface CustomComponents {
  [key: string]: ReactElement;
}

interface Props {
  as?: string | FunctionComponent | ComponentClass;
  components?: CustomComponents;
  html: string;
}

const processNodeDefinitions = new Converter.ProcessNodeDefinitions(React);
const htmlToReactParser = new Converter.Parser();
const parseInstructions = [
  // Replace anchors with Link component
  {
    shouldProcessNode(node): boolean {
      return node.name === "a";
    },
    processNode(node, children) {
      const { href, ...props } = node.attribs;

      return createElement(
        Link,
        {
          ...props,
          ...deeplinkTranslator(href),
        },
        children
      );
    },
  },
  // Wrap tables for RWD
  {
    shouldProcessNode(node): boolean {
      return node.name === "table";
    },
    processNode(node, children) {
      return createElement("div", { style: { overflowX: "auto" } }, createElement("table", node.attribs, children));
    },
  },
  {
    shouldProcessNode: () => true,
    processNode: processNodeDefinitions.processDefaultNode,
  },
];

function isValidNode(node) {
  return node.name !== "script"; // Do not parse <script> tag
}

export const HtmlToReact: FC<Props> = memo(({ as, components = {}, html, ...props }) => {
  const componentKeys = Object.keys(components);

  const result = htmlToReactParser.parseWithInstructions(html, isValidNode, [
    {
      replaceChildren: false,
      shouldProcessNode(node) {
        return componentKeys.includes(node.name);
      },
      processNode(node, children) {
        return cloneElement(components[node.name], node.attribs, children);
      },
    },
    ...parseInstructions,
  ]);

  if (as) {
    return createElement(as, props, result);
  }

  return result;
});
