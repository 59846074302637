import React from "react";
import Head from "next/head";

import { getHomeScreen } from "resources/AudiotekaApi";
import { getLocationInfo } from "utils/getLocationInfo";
import { getLocalePaths } from "utils/getLocalePaths";
import { getAlternateLinks } from "utils/getAlternateLinks";
import Home from "modules/Home";

export default function HomePage({ initialHomeScreen }) {
  return (
    <>
      <Head>{getAlternateLinks("home")}</Head>
      <Home initialHomeScreen={initialHomeScreen} />
    </>
  );
}

export const getStaticProps = async ({ params: { locale } }) => {
  const { catalogId } = getLocationInfo(locale);
  const initialHomeScreen = await getHomeScreen(catalogId);

  const reducedInitialHomeScreen = {
    ...initialHomeScreen,
    _embedded: {
      ...initialHomeScreen._embedded,
      "app:screen-section": initialHomeScreen._embedded["app:screen-section"].map((section) => {
        if (section.type === "product-grid") {
          return {
            ...section,
            _embedded: {
              ...section._embedded,
              "app:product": section._embedded["app:product"]
                .slice(0, 20)
                .map((product) => ({ ...product, _embedded: null })),
            },
          };
        }

        if (section.type === "product-grid-promo") {
          return {
            ...section,
            _embedded: {
              ...section._embedded,
              "app:product": section._embedded["app:product"]
                .slice(0, 5)
                .map((product) => ({ ...product, _embedded: null })),
            },
          };
        }

        return section;
      }),
    },
  };

  return {
    props: { initialHomeScreen: reducedInitialHomeScreen },
    revalidate: 3600,
  };
};

export async function getStaticPaths() {
  return {
    paths: getLocalePaths(),
    fallback: false,
  };
}
