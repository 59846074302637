import React from "react";

import { buildLink } from "./buildLink";

export const getAlternateLinks = (pathname: Parameters<typeof buildLink>[0]) => {
  const locales = [];
  // const locales = ["cz", "pl"] // use when czech language is available

  return locales.map((locale) => (
    <link key={locale} rel="alternate" href={buildLink(pathname, locale, undefined, true)} hrefLang={locale} />
  ));
};
