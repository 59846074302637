import React from "react";
import { useTranslations } from "next-intl";

import { Breakpoint } from "modules/DesignSystem/breakpoint";
import Markdown from "components/markdown";
import { NewIcon } from "components/NewIcon";
import { BgPicture, Content, IconWrapper, Image, Item, List, MoreBtn, Root, Title } from "./AboutClub.styled";

import backgroundImg from "./img/background.jpg";
import backgroundMobileImg from "./img/background-m.jpg";

const Icon = ({ icon, ...props }: React.ComponentProps<typeof NewIcon>) => (
  <IconWrapper>
    <NewIcon icon={icon} {...props} />
  </IconWrapper>
);

const AboutClub = () => {
  const t = useTranslations();

  return (
    <Root>
      <BgPicture>
        <source media={`(min-width: ${Breakpoint.lg}px)`} srcSet={backgroundImg.src} />
        <img loading="lazy" src={backgroundMobileImg.src} alt="" />
      </BgPicture>
      <Content>
        <Title>{t("about_club.title")}</Title>
        <Image width="450" height="468" src="/img/about-club-phones.png" alt="Audioteka Club" />
        <List first>
          <Item>
            <Icon icon="touchscreen" />
            <Markdown text={t("about_club.item1")} />
          </Item>
          <Item>
            <Icon icon="headphones-alt" />
            <Markdown text={t("about_club.item2")} />
          </Item>
          <Item>
            <Icon icon="promo" />
            <Markdown text={t("about_club.item3")} />
          </Item>
        </List>
        <List>
          <Item>
            <Icon icon="share" />
            <Markdown text={t("about_club.item4")} />
          </Item>
          <Item>
            <Icon icon="kid" />
            <Markdown text={t("about_club.item5")} />
          </Item>
          <Item>
            <Icon icon="fire" />
            <Markdown text={t("about_club.item6")} />
          </Item>
        </List>
        <MoreBtn href={t("about_club.more_link")}>{t("about_club.more")}</MoreBtn>
      </Content>
    </Root>
  );
};

export default AboutClub;
